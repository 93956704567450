.bg-card-bg {
    background-image: linear-gradient(140.14deg,rgba(0,182,191,.15),rgba(27,22,89,.1) 86.61%),linear-gradient(321.82deg,#18134d,#1b1659);
    border-radius: 1.25rem;
}
.text-logo-color{
    --tw-text-opacity: 1;
    color: rgb(171 196 255/var(--tw-text-opacity));
}
.text-color {
    color: rgba(196,214,255,.5)!important;
}
.text-xs-swap {
    font-size: .75rem;
    line-height: 1rem;
}
.text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
}
.text-p-color {
    --tw-text-opacity: 1;
    color: rgb(218 46 239/var(--tw-text-opacity));
}
.head{
    color: #fff;
    /* display: flex;
    justify-content:center ;
    align-items: center; */
}
